import React from "react";
import { useParams } from "react-router-dom";
import ReportErrorDetail from "../../../../components/Report/Error/ReportErrorDetail";
import ReportErrorDetailRtExit from "../../../../components/Report/Error/ReportErrorDetailRtExit";
import PageNotFound from "../../../Common/Error/PageNotFound";

/**
 * @author Thiago Guimarães
 * @date 16/04/2021
 */
function Error() {
  const { elementType, command, field } = useParams();

  if (elementType === "msc" && command === "mgisp") {
    return (
      <ReportErrorDetail
        key="1"
        vendorId="1"
        elementTypeId="9"
        commandId="1"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/msc/mgisp"
        reportTitle="Report MSC"
      />
    );
  } else if (elementType === "hlr" && command === "hercp") {
    return (
      <ReportErrorDetail
        key="2"
        vendorId="1"
        elementTypeId="5"
        commandId="2"
        field={field}
        keyLabel="GLOBAL TITLE"
        baseUrl="/report/error/hlr/hercp"
        reportTitle="Report HLR"
      />
    );
  } else if (elementType === "mme" && command === "connect-plmn") {
    return (
      <ReportErrorDetail
        key="3"
        vendorId="3"
        elementTypeId="10"
        commandId="3"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/mme/connect-plmn"
        reportTitle="Report SGSN/MME (CONNECT PLMN)"
      />
    );
  } else if (elementType === "mme" && command === "imsigt") {
    return (
      <ReportErrorDetail
        key="4"
        vendorId="3"
        elementTypeId="10"
        commandId="4"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/mme/imsigt"
        reportTitle="Report SGSN/MME (IMSIGT)"
      />
    );
  } else if (elementType === "mme" && command === "imsihss") {
    return (
      <ReportErrorDetail
        key="5"
        vendorId="3"
        elementTypeId="10"
        commandId="5"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/mme/imsihss"
        reportTitle="Report SGSN/MME (IMSIHSS)"
      />
    );
  } else if (elementType === "hss" && command === "esm-plmn-container") {
    return (
      <ReportErrorDetail
        key="6"
        vendorId="1"
        elementTypeId="6"
        commandId="6"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/hss/esm-plmn-container"
        reportTitle="Report HSS (ESM PLMN CONTAINER)"
      />
    );
  } else if (elementType === "hss" && command === "dia-cfg-routing-container") {
    return (
      <ReportErrorDetail
        key="7"
        vendorId="1"
        elementTypeId="6"
        commandId="7"
        field={field}
        keyLabel="REALM"
        baseUrl="/report/error/hss/dia-cfg-routing-container"
        reportTitle="Report HSS (DIA CFG ROUTING CONTAINER)"
      />
    );
  } else if (elementType === "edns" && command === "2g-3g") {
    return (
      <ReportErrorDetail
        key="8"
        vendorId="2"
        elementTypeId="2"
        commandId="8"
        field={field}
        keyLabel="APN OPERATOR IDENTIFIER"
        baseUrl="/report/error/edns/2g-3g"
        reportTitle="Report EDNS (2G 3G)"
      />
    );
  } else if (elementType === "edns" && command === "4g") {
    return (
      <ReportErrorDetail
        key="9"
        vendorId="2"
        elementTypeId="2"
        commandId="9"
        field={field}
        keyLabel="REALM"
        baseUrl="/report/error/edns/4g"
        reportTitle="Report EDNS (4G)"
      />
    );
  } else if (elementType === "pts" && command === "sccpgt-k2r-nngt") {
    return (
      <ReportErrorDetail
        key="10"
        vendorId="3"
        elementTypeId="13"
        commandId="15"
        field={field}
        keyLabel="ADDRESS MESSAGE"
        baseUrl="/report/error/pts/sccpgt-k2r-nngt"
        reportTitle="Report PTS (K2R NNGT - LST SCCPGT)"
      />
    );
  } else if (elementType === "dra" && command === "dmrt") {
    return (
      <ReportErrorDetail
        key="11"
        vendorId="3"
        elementTypeId="1"
        commandId="10"
        field={field}
        keyLabel="REALM"
        baseUrl="/report/error/dra/dmrt"
        reportTitle="Report DRA (DMRT)"
      />
    );
  } else if (elementType === "dra" && command === "rtimsi") {
    return (
      <ReportErrorDetail
        key="12"
        vendorId="3"
        elementTypeId="1"
        commandId="14"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/dra/rtimsi"
        reportTitle="Report DRA (RTIMSI)"
      />
    );
  } else if (elementType === "dra" && command === "rtresult") {
    return (
      <ReportErrorDetail
        key="13"
        vendorId="3"
        elementTypeId="1"
        commandId="16"
        field={field}
        keyLabel="REALM"
        baseUrl="/report/error/dra/rtresult"
        reportTitle="Report DRA (RTRESULT)"
      />
    );
  } else if (elementType === "dra" && command === "rtdhost") {
    return (
      <ReportErrorDetail
        key="14"
        vendorId="3"
        elementTypeId="1"
        commandId="11"
        field={field}
        keyLabel="REALM"
        baseUrl="/report/error/dra/rtdhost"
        reportTitle="Report DRA (RTDHOST)"
      />
    );
  } else if (elementType === "dra" && command === "rtorealm") {
    return (
      <ReportErrorDetail
        key="15"
        vendorId="3"
        elementTypeId="1"
        commandId="12"
        field={field}
        keyLabel="REALM"
        baseUrl="/report/error/dra/rtorealm"
        reportTitle="Report DRA (RTOREALM)"
      />
    );
  } else if (elementType === "dra" && command === "rtohost") {
    return (
      <ReportErrorDetail
        key="16"
        vendorId="3"
        elementTypeId="1"
        commandId="13"
        field={field}
        keyLabel="REALM"
        baseUrl="/report/error/dra/rtohost"
        reportTitle="Report DRA (RTOHOST)"
      />
    );
  } else if (elementType === "pts" && command === "sccpgt-vrs-nngt") {
    return (
      <ReportErrorDetail
        key="17"
        vendorId="3"
        elementTypeId="13"
        commandId="17"
        field={field}
        keyLabel="ADDRESS MESSAGE"
        baseUrl="/report/error/pts/sccpgt-vrs-nngt"
        reportTitle="Report PTS (VRS-NNGT - LST SCCPGT)"
      />
    );
  } else if (elementType === "pts" && command === "sccpgt-vrs-mgt") {
    return (
      <ReportErrorDetail
        key="18"
        vendorId="3"
        elementTypeId="13"
        commandId="18"
        field={field}
        keyLabel="ADDRESS MESSAGE"
        baseUrl="/report/error/pts/sccpgt-vrs-mgt"
        reportTitle="Report PTS (VRS MGT - LST SCCPGT)"
      />
    );
  } else if (elementType === "pts" && command === "sccpgt-normal-nngt") {
    return (
      <ReportErrorDetail
        key="19"
        vendorId="3"
        elementTypeId="13"
        commandId="19"
        field={field}
        keyLabel="ADDRESS MESSAGE"
        baseUrl="/report/error/pts/sccpgt-normal-nngt"
        reportTitle="Report PTS (NORMAL NNGT - LST SCCPGT)"
      />
    );
  } else if (elementType === "pts" && command === "sccpgt-normal-mgt") {
    return (
      <ReportErrorDetail
        key="20"
        vendorId="3"
        elementTypeId="13"
        commandId="20"
        field={field}
        keyLabel="ADDRESS MESSAGE"
        baseUrl="/report/error/pts/sccpgt-normal-mgt"
        reportTitle="Report PTS (NORMAL MGT - LST SCCPGT)"
      />
    );
  } else if (elementType === "pts" && command === "sccpgt-unknown") {
    return (
      <ReportErrorDetail
        key="21"
        vendorId="3"
        elementTypeId="13"
        commandId="21"
        field={field}
        keyLabel="ADDRESS MESSAGE"
        baseUrl="/report/error/pts/sccpgt-unknown"
        reportTitle="Report PTS (UNKNOWN - LST SCCPGT)"
      />
    );
  } else if (elementType === "glr" && command === "glr") {
    return (
      <ReportErrorDetail
        key="22"
        vendorId="6"
        elementTypeId="4"
        commandId="22"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/glr/glr"
        reportTitle="Report GLR"
      />
    );
  } else if (elementType === "glr" && command === "glr-wl") {
    return (
      <ReportErrorDetail
        key="23"
        vendorId="6"
        elementTypeId="4"
        commandId="23"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/glr/glr-wl"
        reportTitle="Report GLR WL"
      />
    );
  } else if (elementType === "sor" && command === "vlr") {
    return (
      <ReportErrorDetail
        key="24"
        vendorId="6"
        elementTypeId="12"
        commandId="24"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/sor/vlr"
        reportTitle="Report SOR VLR"
      />
    );
  } else if (elementType === "sor" && command === "sgsn") {
    return (
      <ReportErrorDetail
        key="25"
        vendorId="6"
        elementTypeId="12"
        commandId="25"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/sor/sgsn"
        reportTitle="Report SOR SGSN"
      />
    );
  } else if (elementType === "sor" && command === "mme") {
    return (
      <ReportErrorDetail
        key="26"
        vendorId="6"
        elementTypeId="12"
        commandId="26"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/sor/mme"
        reportTitle="Report SOR MME"
      />
    );
  } else if (elementType === "hss" && command === "configuration-container") {
    return (
      <ReportErrorDetail
        key="27"
        vendorId="1"
        elementTypeId="6"
        commandId="27"
        field={field}
        keyLabel="NETWORK DOMAIN"
        baseUrl="/report/error/hss/configuration-container"
        reportTitle="Report HSS (Configuration Container)"
      />
    );
  } else if (elementType === "ugw" && command === "virtual-apn") {
    return (
      <ReportErrorDetail
        key="28"
        vendorId="3"
        elementTypeId="14"
        commandId="28"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/ugw/virtual-apn"
        reportTitle="Report UGW (Virtual APN)"
      />
    );
  } else if (elementType === "dra" && command === "rtexit") {
    return (
      <ReportErrorDetailRtExit
        key="29"
        vendorId="3"
        elementTypeId="1"
        commandId="29"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/dra/rtexit"
        reportTitle="Report DRA (RTOEXIT)"
      />
    );
  } else if (elementType === "mme" && command === "voicedeploy") {
    return (
      <ReportErrorDetail
        key="30"
        vendorId="3"
        elementTypeId="10"
        commandId="30"
        field={field}
        keyLabel="IMSI"
        baseUrl="/report/error/mme/voicedeploy"
        reportTitle="Huawei SGSN/MME (VOICE DEPLOY)"
      />
    );
  } else {
    return <PageNotFound />;
  }
}

export default Error;
