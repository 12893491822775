import api from "./api";

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/plmns")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function findComboBox() {
  return new Promise((resolve, reject) => {
    api
      .get("/plmns/combo-box")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/plmns/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function merge(id, plmn) {
  return new Promise((resolve, reject) => {
    api({
      method: id ? "put" : "post",
      url: id ? `/plmns/${id}` : "/plmns",
      data: plmn,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteById(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/plmns/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
export function getExportSqm() {
  return new Promise((resolve, reject) => {
    api
      .get(`/plmns/sqm/export`, { responseType: "blob" })
      .then((response) => {
        let fileName = "file.xls";
        if (response.headers && response.headers["content-disposition"]) {
          fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .replaceAll('"', "")
            .replaceAll("%20", " ");
        }

        const blob = new Blob([response.data], {
          type: "application/excel",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}
