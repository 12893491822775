import React from "react";
import { useParams } from "react-router-dom";
import IgnoredCases from "../../../../components/IgnoredCases/IgnoredCases";
import PageNotFound from "../../../Common/Error/PageNotFound";

/**
 * @author Guilherme Almeida
 * @date 04/06/2021
 */
function IgnoredCasesList() {
  const { elementType, vendor, command } = useParams();

  if (elementType === "msc" && vendor === "ericsson" && command === "mgisp") {
    return (
      <IgnoredCases
        key="1"
        vendorId="1"
        elementTypeId="9"
        commandId="1"
        keyLabel="IMSI"
        title="Ericsson MSC (MGISP)"
      />
    );
  } else if (
    elementType === "hlr" &&
    vendor === "ericsson" &&
    command === "hercp"
  ) {
    return (
      <IgnoredCases
        key="2"
        vendorId="1"
        elementTypeId="5"
        commandId="2"
        keyLabel="GLOBAL TITLE"
        title="Ericsson HLR (HERCP)"
      />
    );
  } else if (
    elementType === "sgsn-mme" &&
    vendor === "huawei" &&
    command === "connect-plmn"
  ) {
    return (
      <IgnoredCases
        key="3"
        vendorId="3"
        elementTypeId="10"
        commandId="3"
        keyLabel="IMSI"
        title="Huawei SGSN/MME (Connect PLMN)"
      />
    );
  } else if (
    elementType === "sgsn-mme" &&
    vendor === "huawei" &&
    command === "imsi-gt"
  ) {
    return (
      <IgnoredCases
        key="4"
        vendorId="3"
        elementTypeId="10"
        commandId="4"
        keyLabel="IMSI"
        title="Huawei SGSN/MME (IMSI GT)"
      />
    );
  } else if (
    elementType === "sgsn-mme" &&
    vendor === "huawei" &&
    command === "imsi-hss"
  ) {
    return (
      <IgnoredCases
        key="5"
        vendorId="3"
        elementTypeId="10"
        commandId="5"
        keyLabel="IMSI"
        title="Huawei SGSN/MME (IMSI HSS)"
      />
    );
  } else if (
    elementType === "hss" &&
    vendor === "ericsson" &&
    command === "esm-plmn-container"
  ) {
    return (
      <IgnoredCases
        key="6"
        vendorId="1"
        elementTypeId="6"
        commandId="6"
        keyLabel="IMSI"
        title="Ericsson HSS (Esm PLMN Container)"
      />
    );
  } else if (
    elementType === "hss" &&
    vendor === "ericsson" &&
    command === "dia-cfg-routing-container"
  ) {
    return (
      <IgnoredCases
        key="7"
        vendorId="1"
        elementTypeId="6"
        commandId="7"
        keyLabel="REALM"
        title="Ericsson HSS (Dia Cfg Routing Container)"
      />
    );
  } else if (elementType === "edns" && vendor === "f5" && command === "2g-3g") {
    return (
      <IgnoredCases
        key="8"
        vendorId="2"
        elementTypeId="2"
        commandId="8"
        keyLabel="APN OPERATOR IDENTIFIER"
        title="F5 EDNS (2G/3G)"
      />
    );
  } else if (elementType === "edns" && vendor === "f5" && command === "4g") {
    return (
      <IgnoredCases
        key="9"
        vendorId="2"
        elementTypeId="2"
        commandId="9"
        keyLabel="REALM"
        title="F5 EDNS (4G)"
      />
    );
  } else if (
    elementType === "dra" &&
    vendor === "huawei" &&
    command === "dmrt"
  ) {
    return (
      <IgnoredCases
        key="10"
        vendorId="3"
        elementTypeId="1"
        commandId="10"
        keyLabel="REALM"
        title="Huawei DRA (DMRT)"
      />
    );
  } else if (
    elementType === "dra" &&
    vendor === "huawei" &&
    command === "rtdhost"
  ) {
    return (
      <IgnoredCases
        key="11"
        vendorId="3"
        elementTypeId="1"
        commandId="11"
        keyLabel="REALM"
        title="Huawei DRA (RTDHOST)"
      />
    );
  } else if (
    elementType === "dra" &&
    vendor === "huawei" &&
    command === "rtorealm"
  ) {
    return (
      <IgnoredCases
        key="12"
        vendorId="3"
        elementTypeId="1"
        commandId="12"
        keyLabel="REALM"
        title="Huawei DRA (RTOREALM)"
      />
    );
  } else if (
    elementType === "dra" &&
    vendor === "huawei" &&
    command === "rtohost"
  ) {
    return (
      <IgnoredCases
        key="13"
        vendorId="3"
        elementTypeId="1"
        commandId="13"
        keyLabel="REALM"
        title="Huawei DRA (RTOHOST)"
      />
    );
  } else if (
    elementType === "dra" &&
    vendor === "huawei" &&
    command === "rtimsi"
  ) {
    return (
      <IgnoredCases
        key="14"
        vendorId="3"
        elementTypeId="1"
        commandId="14"
        keyLabel="IMSI"
        title="Huawei DRA (RTIMSI)"
      />
    );
  } else if (
    elementType === "dra" &&
    vendor === "huawei" &&
    command === "rtresult"
  ) {
    return (
      <IgnoredCases
        key="15"
        vendorId="3"
        elementTypeId="1"
        commandId="16"
        keyLabel="REALM"
        title="Huawei DRA (RTRESULT)"
      />
    );
  } else if (
    elementType === "pts" &&
    vendor === "huawei" &&
    command === "sccpgt-k2r-nngt"
  ) {
    return (
      <IgnoredCases
        key="16"
        vendorId="3"
        elementTypeId="13"
        commandId="15"
        keyLabel="ADDRESS MESSAGE"
        title="Huawei PTS (K2R NNGT)"
      />
    );
  } else if (
    elementType === "pts" &&
    vendor === "huawei" &&
    command === "sccpgt-vrs-nngt"
  ) {
    return (
      <IgnoredCases
        key="17"
        vendorId="3"
        elementTypeId="13"
        commandId="17"
        keyLabel="ADDRESS MESSAGE"
        title="Huawei PTS (VRS NNGT)"
      />
    );
  } else if (
    elementType === "pts" &&
    vendor === "huawei" &&
    command === "sccpgt-vrs-mgt"
  ) {
    return (
      <IgnoredCases
        key="18"
        vendorId="3"
        elementTypeId="13"
        commandId="18"
        keyLabel="ADDRESS MESSAGE"
        title="Huawei PTS (VRS MGT)"
      />
    );
  } else if (
    elementType === "pts" &&
    vendor === "huawei" &&
    command === "sccpgt-normal-nngt"
  ) {
    return (
      <IgnoredCases
        key="19"
        vendorId="3"
        elementTypeId="13"
        commandId="19"
        keyLabel="ADDRESS MESSAGE"
        title="Huawei PTS (NORMAL NNGT)"
      />
    );
  } else if (
    elementType === "pts" &&
    vendor === "huawei" &&
    command === "sccpgt-normal-mgt"
  ) {
    return (
      <IgnoredCases
        key="20"
        vendorId="3"
        elementTypeId="13"
        commandId="20"
        keyLabel="ADDRESS MESSAGE"
        title="Huawei PTS (NORMAL MGT)"
      />
    );
  } else if (
    elementType === "glr" &&
    vendor === "sixbell" &&
    command === "glr"
  ) {
    return (
      <IgnoredCases
        key="21"
        vendorId="6"
        elementTypeId="4"
        commandId="22"
        keyLabel="IMSI"
        title="Sixbell GLR"
      />
    );
  } else if (
    elementType === "glr" &&
    vendor === "sixbell" &&
    command === "glr-wl"
  ) {
    return (
      <IgnoredCases
        key="22"
        vendorId="6"
        elementTypeId="4"
        commandId="23"
        keyLabel="IMSI"
        title="Sixbell GLR (GLR WL)"
      />
    );
  } else if (
    elementType === "sor" &&
    vendor === "sixbell" &&
    command === "vlr"
  ) {
    return (
      <IgnoredCases
        key="23"
        vendorId="6"
        elementTypeId="12"
        commandId="24"
        keyLabel="IMSI"
        title="Sixbell SOR (VLR)"
      />
    );
  } else if (
    elementType === "sor" &&
    vendor === "sixbell" &&
    command === "sgsn"
  ) {
    return (
      <IgnoredCases
        key="24"
        vendorId="6"
        elementTypeId="12"
        commandId="25"
        keyLabel="IMSI"
        title="Sixbell SOR (SGSN)"
      />
    );
  } else if (
    elementType === "sor" &&
    vendor === "sixbell" &&
    command === "mme"
  ) {
    return (
      <IgnoredCases
        key="25"
        vendorId="6"
        elementTypeId="12"
        commandId="26"
        keyLabel="IMSI"
        title="Sixbell SOR (MME)"
      />
    );
  } else if (
    elementType === "hss" &&
    vendor === "ericsson" &&
    command === "configuration-container"
  ) {
    return (
      <IgnoredCases
        key="26"
        vendorId="1"
        elementTypeId="6"
        commandId="27"
        keyLabel="NETWORK DOMAIN"
        title="Ericsson HSS (Configuration Container)"
      />
    );
  } else if (
    elementType === "ugw" &&
    vendor === "huawei" &&
    command === "virtual-apn"
  ) {
    return (
      <IgnoredCases
        key="27"
        vendorId="3"
        elementTypeId="14"
        commandId="28"
        keyLabel="IMSI"
        title="huawei ugw (Virtual APN)"
      />
    );
  } else if (
    elementType === "mme" &&
    vendor === "huawei" &&
    command === "voicedeploy"
  ) {
    return (
      <IgnoredCases
        key="30"
        vendorId="3"
        elementTypeId="10"
        commandId="30"
        keyLabel="IMSI"
        title="Huawei SGSN/MME (VOICE DEPLOY)"
      />
    );
  } else {
    return <PageNotFound />;
  }
}

export default IgnoredCasesList;
