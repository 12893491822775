import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { FiCheck, FiEdit, FiFilter, FiTrash2, FiX } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../../const/Messages";
import * as OperatorPartnersService from "../../../../../service/OperatorPartnerService";

/**
  @author Guilherme Almeida
  @date 10/02/2021
 */
export default function RoamingPartnersManagementList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [operatorPartners, setOperatorPartners] = useState([]);

  useEffect(() => {
    OperatorPartnersService.findAll()
      .then((operatorPartnersList) => {
        setOperatorPartners(operatorPartnersList);
      })
      .catch(() => {
        toast.error(
          "The system found an unexpected failure during execution. Please, try again in a few seconds."
        );
      })
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line

  function handleDelete(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        OperatorPartnersService.deleteById(id)
          .then(() => {
            OperatorPartnersService.findAll()
              .then((operatorPartnersList) => {
                setOperatorPartners(operatorPartnersList);
                toast.success(OperationSuccessfully);
              })
              .catch(() => {
                toast.error(ErrorUnexpected);
              });
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  function handleDeleteBlocked(tadig) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to unlock?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        OperatorPartnersService.deleteBlockedByTadig(tadig)
          .then(() => {
            OperatorPartnersService.findAll()
              .then((operatorPartnersList) => {
                setOperatorPartners(operatorPartnersList);
                toast.success(OperationSuccessfully);
              })
              .catch(() => {
                toast.error(ErrorUnexpected);
              });
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  function handleCreateBlocked(tadig) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to block?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        OperatorPartnersService.createBlockedByTadig(tadig)
          .then(() => {
            OperatorPartnersService.findAll()
              .then((operatorPartnersList) => {
                setOperatorPartners(operatorPartnersList);
                toast.success(OperationSuccessfully);
              })
              .catch(() => {
                toast.error(ErrorUnexpected);
              });
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  const columns = [
    {
      title: "ORGANISATION",
      field: "organisation",
      width: "15%",
    },
    {
      title: "COUNTRY",
      field: "country",
    },
    {
      title: "TADIG",
      field: "plmn",
    },
    {
      title: "GSM",
      field: "gsm",
    },
    {
      title: "CAMEL",
      field: "camel",
    },
    {
      title: "GPRS",
      field: "gprs",
    },
    {
      title: "LTE",
      field: "lte",
    },
    {
      title: "5G NSA (development)",
      field: "nsa5g",
    },
    {
      title: "VoLTE",
      field: "volte",
    },
    {
      title: "HUB",
      field: "hub",
    },
    {
      title: "IR.21",
      field: "hasIr21",
      render: (obj) => {
        return obj.hasIr21 ? <FiCheck color="green" /> : <FiX color="red" />;
      },
      customSort: (obj1, obj2) => {
        if (obj1.hasIr21 === obj2.hasIr21) {
          return 0;
        } else if (obj1.hasIr21) {
          return -1;
        } else {
          return 1;
        }
      },
      width: "1%",
      lookup: { true: "Found", false: "Not Found" },
    },
    {
      title: " BLOCKED",
      field: "hasBlocked",
      render: (obj) => {
        return obj.hasBlocked ? <FiCheck color="green" /> : <FiX color="red" />;
      },
      customSort: (obj1, obj2) => {
        if (obj1.hasBlocked === obj2.hasBlocked) {
          return 0;
        } else if (obj1.hasBlocked) {
          return -1;
        } else {
          return 1;
        }
      },
      width: "1%",
      lookup: { true: "Blocked", false: "Unlocked" },
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between">
            <h5>Roaming Partners - List</h5>
            <div>
              <Link
                className="btn btn-primary mr-2"
                to={{
                  pathname: "/settings/partners/roaming-partners/reg",
                  state: { id: "" },
                }}
              >
                New Partner
              </Link>
              <Link
                className="btn btn-primary mr-2"
                to={{
                  pathname: "/settings/partners/roaming-partners/upload",
                }}
              >
                Import Data
              </Link>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={operatorPartners}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              icons={{
                Filter: () => <FiFilter />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "Roaming Partners",
                filtering: true,
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
              actions={[
                {
                  icon: () => <FiEdit size={16} color="#007bff" />,
                  tooltip: "EDIT",
                  onClick: (event, rowData) =>
                    history.push("/settings/partners/roaming-partners/reg", {
                      id: rowData.id,
                    }),
                },
                {
                  icon: () => <FiTrash2 size={16} color="#007bff" />,
                  tooltip: "DELETE",
                  onClick: (event, rowData) => handleDelete(rowData.id),
                },
                (row) => {
                  if (row.hasBlocked) {
                    return {
                      icon: () => <FaLockOpen size={16} color="#007bff" />,
                      tooltip: "UNLOCK",
                      onClick: (event, rowData) =>
                        handleDeleteBlocked(rowData.plmn),
                    };
                  } else {
                    return {
                      icon: () => <FaLock size={16} color="#007bff" />,
                      tooltip: "BLOCK",
                      onClick: (event, rowData) =>
                        handleCreateBlocked(rowData.plmn),
                    };
                  }
                },
              ]}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
